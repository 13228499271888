import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Checkin from './pages/checkin';
import Home from './pages/home/index';
import Checklog from './pages/checklog';
import Admin from './pages/admin/index';

function App() {
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function(position) {});
  }, []);

  return <div>
    <Router>
      <Routes>
        <Route path="/"  element={<Checklog />}></Route>
        <Route path="/admin"  element={<Admin />}></Route>
      </Routes>
    </Router>
  </div>;
}

export default App;
