import Config from "../config";

const RequestService = {
    get: async (url: string):Promise<any> => {
        const response = await fetch(Config.api + url);
        if (response.ok) {
            const result = await response.json();
            return result.data || result;
        } else {
            throw(response.text);
        }
    },
    post: async (url: string, data: any):Promise<any> => {
        const response = await fetch(Config.api + url, {
            method: 'POST',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify(data)
        });

        if (response.ok) {
            const result = await response.json();
            return result.data || result;
        } else {
            throw(response.text);
        }
    },
    patch: async (url: string, data?: any):Promise<any> => {
        const response = await fetch(Config.api + url, {
            method: 'PATCH',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify(data || {})
        });

        if (response.ok) {
            const result = await response.json();
            return result.data || result;
        } else {
            throw(response.text);
        }
    }
};

export default RequestService;