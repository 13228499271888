import RequestService from "./request";

const StaffService = {
    retrieve: async ():Promise<StaffResourceShortProps[]> => {
        try {
            const staffs = await RequestService.get("/staffs");
            return staffs;
        } catch(e: any) {
            throw(e.stack || e.message || e.toString());
        }
    }
}

export default StaffService;

export interface StaffResourceShortProps {
    id: string;
    name: string;
}