import React, { useEffect, useState } from 'react';
import logo from './logo.svg';

import Form from 'react-bootstrap/Form'

import styles from './style.module.scss';
import { Spinner, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icon from '@fortawesome/pro-light-svg-icons';
import StaffService, { StaffResourceShortProps } from '../../services/staff';
import ChecklogService, { IChecklogResourceShortProps } from '../../services/checklog';

interface ChecklogDataProps {
    date: string;
    startTime: string;
    endTime: string;
    startCoords: string;
    endCoords: string;
    diffInMinutes: number;
}

function Admin() {
    const [password, setPassword] = useState<string>("");
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [checklogLoaded, setChecklogLoaded] = useState<boolean>(false);

    const [staffs, setStaffs] = useState<StaffResourceShortProps[]>([]);
    const [selectedStaff, setSelectedStaff] = useState<string>("");
    const [selectedMonth, setSelectedMonth] = useState<string>(moment().format("MM"));
    const [selectedYear, setSelectedYear] = useState<string>(moment().format("YYYY"));
    const [checklog, setChecklog] = useState<ChecklogDataProps[]>([]);
    const [headerText, setHeaderText] = useState<string>("");
    const [salary, setSalary] = useState<string>("");
    const [bonus, setBonus] = useState<string>("0");

    const _onLogin = () => {
        if (password === 'soogahadmin') {
            setIsAuthenticated(true);
            init();
        } else {
            setPassword('');
        }
    }

    const _onSubmit = async () => {
        setChecklog([]);
        setChecklogLoaded(false);
        setBonus("0");
        setSalary("0");

        const startDate = moment(`${selectedMonth} ${selectedYear}`, "MM YYYY").startOf('month');
        const endDate = moment(`${selectedMonth} ${selectedYear}`, "MM YYYY").endOf('month');

        const qs: string[] = [];
        qs.push(`staffId=${selectedStaff}`);
        qs.push(`startTime=${startDate.toISOString()}`);
        qs.push(`endTime=${endDate.toISOString()}`);

        const checklogs = await ChecklogService.retrieve(qs.join('&'));
        const data: ChecklogDataProps[] = [];
        const days = endDate.diff(startDate, 'days');
        for (var x=0; x<=days; x++) {
            const date = moment(`${x+1}/${selectedMonth}/${selectedYear}`, 'D/MM/YYYY').format("DD/MM/YYYY")
            const checklog = checklogs.find((ch) => moment(ch.startTime).format("DD/MM/YYYY") === date);
            if (checklog) {
                data.push({
                    date,
                    startTime: moment(checklog.startTime).format("HH:mm"),
                    endTime: checklog.endTime ? moment(checklog.endTime).format("HH:mm") : "-",
                    startCoords: checklog.checkinGeolocation,
                    endCoords: checklog.checkoutGeolocation || "-",
                    diffInMinutes: moment(checklog.endTime).diff(moment(checklog.startTime), 'minutes')
                });
            } else {
                data.push({
                    date,
                    startTime: "-",
                    endTime: "-",
                    startCoords: "-",
                    endCoords: "-",
                    diffInMinutes: 0
                });
            }
        }

        setChecklog(data);
        setChecklogLoaded(true);
        setHeaderText(`Checklog ${staffs.find((st) => st.id === selectedStaff)?.name} bulan ${moment(selectedMonth, "MM").format("MMMM")}`);
    }

    const init = async () => {
        setLoaded(false);
        const staffs = await StaffService.retrieve();
        setStaffs(staffs);
        setLoaded(true);
    }

    const getNumberWithSeparator = (number?: string, separator?:string) => {
        if (parseFloat(number || "0") >= 1) {
            number = (number || "0");//.split(".").join("");
            number = number.replaceAll(".", ",");
            const decimal = number.split(",")[1];
            return number.split(",")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + (decimal ? "," + decimal : "");
        } else if (parseFloat(number || "0") >= 0 && parseFloat(number || "0") < 1) {
            number = (number || "0");//.split(".").join("");
            number = number.replaceAll(".", ",");
            return number;
        } else {
            return number;
        }
    }

    const countSalary = (diffInMinutes: number) => {
        let sal = 0;
        if (diffInMinutes > 0) {
            sal = Math.ceil(parseFloat(salary) / 60 * diffInMinutes);
            return sal;
        } else {
            return 0;
        }
    }

    const countTotalSalary = () => {
        let total = 0;
        checklog.forEach((ch) => {
            total += countSalary(ch.diffInMinutes);
        });
        return total + parseFloat(bonus);
    }

    return <div className={styles.container}>
        {!isAuthenticated ? <div className={styles.login}>
            <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control value={password} type="password" placeholder="" onChange={(evt) => setPassword(evt.target.value || "")} />
            </Form.Group>
            <Form.Group>
                <Button onClick={_onLogin} disabled={password.trim() === ""} style={{ width: '100%' }} variant="primary">Masuk</Button>
            </Form.Group>
        </div> : null}
        {isAuthenticated && !loaded ? <Spinner animation='border' /> : null}
        {isAuthenticated && loaded ? <div className={styles.admin}>
            <Form className={styles.form}>
                <Form.Group>
                    <Form.Label>Pilih Staff</Form.Label>
                    <Form.Select aria-label="Default select example" onChange={(evt) => setSelectedStaff((evt as any).target.value)}>
                        <option></option>
                        {staffs.map((staff) => <option value={staff.id}>{staff.name}</option>)}
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Pilih Bulan</Form.Label>
                    <Form.Select value={selectedMonth} aria-label="Default select example" onChange={(evt) => setSelectedMonth((evt as any).target.value)}>
                        <option></option>
                        <option value={"01"}>Januari</option>
                        <option value={"02"}>Februari</option>
                        <option value={"03"}>Maret</option>
                        <option value={"04"}>April</option>
                        <option value={"05"}>Mei</option>
                        <option value={"06"}>Juni</option>
                        <option value={"07"}>Juli</option>
                        <option value={"08"}>Agustus</option>
                        <option value={"09"}>September</option>
                        <option value={"10"}>Oktober</option>
                        <option value={"11"}>November</option>
                        <option value={"12"}>Desember</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Pilih Tahun</Form.Label>
                    <Form.Select value={selectedYear} aria-label="Default select example" onChange={(evt) => setSelectedYear((evt as any).target.value)}>
                        <option></option>
                        <option value={"2022"}>2022</option>
                        <option value={"2023"}>2023</option>
                        <option value={"2024"}>2024</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Button onClick={_onSubmit} disabled={selectedMonth === "" || selectedStaff === "" || selectedYear === ""} variant="primary">Submit</Button>
                </Form.Group>
            </Form>
        </div> : null}
        {isAuthenticated && !checklogLoaded ? <Spinner animation={'border'} /> : null}
        {isAuthenticated && checklogLoaded ? <div className={styles.checklog}>
            <h4>{headerText}</h4>
            {checklog.length > 0 ? <div className={styles.table}>
                <Form>
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Gaji Per Jam</Form.Label>
                        <Form.Control value={salary} type="text" placeholder="" onChange={(evt) => setSalary(evt.target.value || "")} />
                    </Form.Group>
                </Form>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Tanggal</th>
                            <th>Jam Masuk</th>
                            <th>Jam Keluar</th>
                            <th>Koordinat</th>
                            <th>Total (Jam dan Menit)</th>
                            <th>Gaji hari ini</th>
                        </tr>
                    </thead>
                    <tbody>
                        {checklog.map((ch, idx) => {
                            let mins = 0;
                            let hours = 0;
                            if (ch.diffInMinutes > 0) {
                                hours = Math.floor(ch.diffInMinutes / 60);
                                mins = ch.diffInMinutes - (hours * 60);
                            }

                            return <tr>
                                <td>{ch.date}</td>
                                <td>{ch.startTime}</td>
                                <td>{ch.endTime}</td>
                                <td>
                                    Masuk: {ch.startCoords}<br/>
                                    Keluar: {ch.endCoords}
                                </td>
                                <td>{ch.diffInMinutes > 0 ? `${hours} jam, ${mins} menit` : "-"}</td>
                                <td>{ch.diffInMinutes > 0 ? `Rp. ${getNumberWithSeparator(countSalary(ch.diffInMinutes) + "")}` : '-'}</td>
                            </tr>
                        })}
                        <tr>
                            <td colSpan={5} align={'right'}>Bonus</td>
                            <td>
                                <Form.Group>
                                    <Form.Control value={bonus} type="text" placeholder="" onChange={(evt) => setBonus(evt.target.value || "")} />
                                </Form.Group>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5} align={'right'}>Total</td>
                            <td>Rp. {getNumberWithSeparator(countTotalSalary() + "")}</td>
                        </tr>
                    </tbody>
                </Table>
            </div> : "Checklog tidak ditemukan"}
        </div> : null}
    </div>;
}

export default Admin;
