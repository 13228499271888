import React, { useEffect, useState } from 'react';
import logo from './logo.svg';

import Form from 'react-bootstrap/Form'

import styles from './style.module.scss';
import StaffService, { StaffResourceShortProps } from '../../services/staff';
import { Spinner, Button } from 'react-bootstrap';
import moment from 'moment';
import ChecklogService from '../../services/checklog';

function Checkin(props: any) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [staffs, setStaffs] = useState<StaffResourceShortProps[]>([]);
  const [time, setTime] = useState<string>(moment().format("HH:mm:ss"));
  const [selectedStaff, setSelectedStaff] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const init = async () => {
    const staffs = await StaffService.retrieve();
    setStaffs(staffs.filter((st) => {
      return (props.excluded || []).indexOf(st.id) < 0;
    }));
    setLoaded(true);

    setInterval(timer, 1000);
  }

  const timer = () => {
    setTime(moment().format("HH:mm:ss"));
  }

  const _onSelectedStaffChanged = (evt: any) => {
    setSelectedStaff(evt.target.value);
  }

  useEffect(() => {
    init();
  }, []);

  const _onSubmit = () => {
    setSubmitting(true);
    navigator.geolocation.getCurrentPosition(async (position) => {
      const {latitude, longitude} = position.coords;

      await ChecklogService.create({
        staffId: selectedStaff,
        checkinGeolocation: `${latitude},${longitude}`
      });

      setSubmitting(false);
      props.onRefresh();
    });
  }

  return <div className={styles.container}>
    <div className={styles.card}>
      {!loaded ? <Spinner animation={'border'} /> : null}
      {loaded ? <Form>
        <Form.Group className="mb-3">
          <Form.Label>Nama</Form.Label>
          <Form.Select disabled={submitting} aria-label="Default select example" onChange={_onSelectedStaffChanged}>
            <option>Pilih nama ...</option>
            {staffs.map((staff) => <option value={staff.id}>{staff.name}</option>)}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label style={{width: '100%'}}>Tanggal & Jam</Form.Label>
          <Form.Text>{moment().format("DD/MM/YYYY")} | {time}</Form.Text>
        </Form.Group>
        <Form.Group>
          {!submitting ? <Button onClick={_onSubmit} disabled={!selectedStaff} style={{width: '100%'}} variant="primary">Masuk</Button> : null}
          {submitting ? <Spinner animation={'border'} size={'sm'} /> : null}
        </Form.Group>
      </Form> : null}
    </div>
  </div>;
}

export default Checkin;
