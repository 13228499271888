import RequestService from "./request";
import { StaffResourceShortProps } from './staff';

const ChecklogService = {
    retrieve: async (qs?:string):Promise<IChecklogResourceShortProps[]> => {
        try {
            const checklog = await RequestService.get(`/checklog?${qs || ''}`);
            return checklog;
        } catch(e: any) {
            throw(e.stack || e.message || e.toString());
        }
    },
    create: async (data: any):Promise<IChecklogResourceShortProps> => {
        try {
            const checklog = await RequestService.post("/checklog", data);
            return checklog;
        } catch(e: any) {
            throw(e.stack || e.message || e.toString());
        }
    },
    update: async (id: string, data:any):Promise<IChecklogResourceShortProps> => {
        try {
            const checklog = await RequestService.patch(`/checklog/${id}`, data);
            return checklog;
        } catch(e: any) {
            throw(e.stack || e.message || e.toString());
        }
    }
}

export default ChecklogService;

export interface IChecklogResourceShortProps {
    id: string;
    staffId: string;
    startTime: string;
    endTime?: string;
    checkinGeolocation: string;
    checkoutGeolocation?: string;
    staff: StaffResourceShortProps;
}