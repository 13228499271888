import React, { useEffect, useState } from 'react';
import logo from './logo.svg';

import Form from 'react-bootstrap/Form'

import styles from './style.module.scss';
import { Spinner, Button, Table } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icon from '@fortawesome/pro-light-svg-icons';
import Checkin from '../checkin';
import ChecklogService, { IChecklogResourceShortProps } from '../../services/checklog';

function Checklog() {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [time, setTime] = useState<string>(moment().format("HH:mm:ss"));
    const [checklog, setChecklog] = useState<IChecklogResourceShortProps[]>([]);

    const refresh = async () => {
        setLoaded(false);
        const checklog = await ChecklogService.retrieve(`startTime=${moment().startOf('day').toISOString()}`);
        setChecklog(checklog);
        setLoaded(true);
    }

    useEffect(() => {
        refresh();
        setInterval(timer, 1000);
    }, [])

    const timer = () => {
        setTime(moment().format("HH:mm:ss"));
    }

    const _onCheckout = async (id: string, name: string) => {
        if (window.confirm("Apakah anda yakin checkout untuk " + name + "?")) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const {latitude, longitude} = position.coords;
                setLoaded(false);
                await ChecklogService.update(id, {checkoutGeolocation: `${latitude},${longitude}`});
                refresh();
            })
        }
    }

    return <div className={styles.container}>
        <div className={styles.header}>
            <div>
                <h3 className={styles.dateAndTimeTitle}>Tanggal</h3>
                <p className={styles.dateAndTime}>{moment().format('DD/MM/YYYY')}</p>
            </div>
            <div>
                <h3 className={styles.dateAndTimeTitle}>Jam</h3>
                <p className={styles.dateAndTime}>{time}</p>
            </div>
        </div>
        {!loaded ? <Spinner animation={'border'} /> : null}
        {loaded ? <div className={styles.content}>
            <div className={styles.current}>
                <h3 className={styles.title}>Checklog Hari Ini</h3>
                {checklog.map((ch) => {
                    return <div className={`${styles.checklog} ${styles.card}`}>
                        <div className={styles.group}>
                            <label>Nama</label>
                            <h4 className={styles.staffName}>{ch.staff.name}</h4>
                        </div>
                        <div className={styles.group}>
                            <label>Mulai</label>
                            <h4 className={styles.staffName}>{moment(ch.startTime).format("HH:mm:ss")}</h4>
                        </div>
                        <div className={styles.group}>
                            {ch.endTime ? <>
                                <label style={{ width: '100%' }}>Selesai</label>
                                <h4 className={styles.staffName}>{moment(ch.endTime).format("HH:mm:ss")}</h4>
                            </> : null}
                            {!ch.endTime ? <Button style={{width: '100%'}} variant="primary" onClick={() => _onCheckout(ch.id, ch.staff.name)}>Selesai</Button> : null}
                        </div>
                    </div>
                })}
            </div>
            <div className={styles.divider}> </div>
            <div className={styles.checkin}>
                <h3 className={styles.title}>Catat Masuk</h3>
                <div className={styles.card}>
                    <Checkin onRefresh={refresh} excluded={checklog.map((ch) => ch.staffId)} />
                </div>
            </div>
        </div> : null}
    </div>;
}

export default Checklog;
